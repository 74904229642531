
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'add-trainer',
  components: {
    Form,
    Field,
    ErrorMessage,
    Datatable,
  },
  data() {
    return {
      entityTypeList: {},
      createTrainer: false,
      componentKey: 0,
      batch: {
        entity_type_role_id: '',
        entity_type_id: '',
      },
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Menu',
          key: 'menu',
          width: '5px',
        },
        {
          name: 'Action  Name',
          key: 'action_name',
          width: '5px',
        },
      ],
      checkedMenu: [],
      editpanel: false,
      menusActionrole: [] as any,
      actions: [],
      text: '',
      menus: [] as any,
      entityTyperoleList: {},
      entityInfos: [],
      institutes: [],
      empDistricts: [],
      nidData: {},
      loading: false,
      showTable: false,
      showTrainerInfo: false,
      all_roles:[] as any,
      selectedMenus: [] as any,
      selectedActions: [] as any, 
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getAllactions();
    await this.getAllMenus();
    await this.getAllRoles();
  },
  methods: {
    edit(data) {
      // this.editpanel = true;
      // this.batch.menu_name = data.menu.menu_name_en;
      // this.batch.menu_id = data.menu.id;
    },
    check(e) {
      let val = e.target.data;
    },
    // mergeToJSON() {
    // 	let result = {};
    //   this.checkedMenu.forEach((val) => {
    //   	let valAsObject = JSON.parse(val);
    //   	for (let [key, value] of Object.entries(valAsObject)) {
    //     	if(!result.hasOwnProperty(key)) result[key] = [];
    //       result[key].push(value);
    //     }
    //   });
    // 	console.log(result);
    // },
    menuPermission() {
      this.loading = true;
      let type = '';
      if (this.batch.entity_type_id == '1004') {
        type = 'doptor';
      } else {
        type = 'tms';
      }
      ApiService.get(
        'menuactionrole/all_actions_custom?entity_type_role_id=' +
          this.batch.entity_type_role_id
      )
        .then((response) => {
          this.selectedMenus=[] as any;
          this.selectedActions=[] as any;
          if (response.data.status == 'success') {
            this.loading = false;
            this.menusActionrole = response.data.data;
            this.menusActionrole.forEach(element=>{
              if(element?.menu_checked==1)
              {
                this.selectedMenus.push(element?.id);
              }
              element.actions.forEach(action_element => {
                if(action_element?.action_checked==1)
                {
                  this.selectedActions.push(action_element?.id);
                }
              });
            });
            this.showTable = true;
            this.componentKey += 1;
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async getEntityTypes() {
      await ApiService.get('configurations/entity_type/list')
        .then((response) => {
          this.entityTypeList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypesRoles() {
      await ApiService.get(
        'entity_type_role/role/allroles?entity_type_id=' +
          this.batch.entity_type_id
      )
        .then((response) => {
          this.entityTyperoleList = [];
          this.entityTyperoleList = response.data.data.role;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      // console.log(this.batch);
      this.loading = true;
      let formData = new FormData();
      // formData.set('menu_action', data);
      formData.set('selectedMenus', this.selectedMenus);
      formData.set('selectedActions', this.selectedActions);
      for (var key in this.batch) {
        formData.set(key, this.batch[key]);
      }
      //this.VUE_APP_MENU_ACTION_SAVE_API

      ApiService.post(this.VUE_APP_MENU_ACTION_SAVE_API, formData)
        .then((response) => {
          this.loading = false;
          this.menusActionrole=[] as any;
          if (response.status == 200 && response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.componentKey += 1;
              this.batch = {
                entity_type_role_id: '',
                entity_type_id: '',
              };
              this.checkedMenu = [];
            });
          } else {
            this.loading = false;
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html:
              response?.data?.error ||
              'Unknown error occured. Please check all the required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
    async getEntityInfos() {
      await ApiService.get('configurations/entity_type/list')
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllactions() {
      await ApiService.get('action/list')
        .then((response) => {
          this.actions = response.data.data;
          // console.log(this.actions);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllMenus() {
      await ApiService.get(this.VUE_APP_MENU_WITH_ACTION_API)
        .then((response) => {
          this.menus = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllRoles()
    {
      await ApiService.get('entity_type_role/role/allroles')
        .then((response) => {
          this.all_roles = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
  },
  setup() {
    const RoleSchema = Yup.object().shape({
      //presentDistrict: Yup.string().required().label("Present District"),
      role_name: Yup.string().required().label('Role Name'),
      role_title: Yup.string().required().label('Role Title'),
      // nid:Yup.number().test('len', 'Must be exactly 5 characters', (nid) => { if(nid) return nid.toString().length === 8; }),
    });
    return {
      RoleSchema,
    };
  },
});
